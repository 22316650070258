import React, { useState, useEffect } from "react";
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import firebase from 'firebase/app';

require('typeface-roboto-slab');



firebase.initializeApp({
	apiKey: "AIzaSyB6UcfvaMqD4E_yPubmXmCc1_5GbXj60bY",
	authDomain: "cteters-portfolio.firebaseapp.com",
	projectId: "cteters-portfolio",
	storageBucket: "cteters-portfolio.appspot.com",
	messagingSenderId: "881837550177",
	appId: "1:881837550177:web:61ad4b200d467e5a381820",
	measurementId: "G-4GYJCBD8GQ"
})


function App() {
	const [offsetY, setOffsetY] = useState(0);
	const handleScroll = () => setOffsetY(window.pageYOffset);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);


	return (
		<>
			<Navbar />
			<section className="App">
				<div
					className="background"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/>
				<Router>

					<Route exact path='/'>
						<Home />
						<About />
						<Projects />
						<Services />
						<Testimonials />
					</Route>
				</Router>
				<Footer />
			</section>
		</>
	);
}

export default App;
