import React from "react";
import './Home.css';


require('typeface-roboto-slab');


function Home() {

	return (
		<>
			<div className="home" id="home">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<h1 className="home__text">
					Christopher Teters
				</h1>
				<h2 className="home__caption">
					Software Engineer
				</h2>
			</div>
		</>
	);
}

export default Home;
