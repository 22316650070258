import React from 'react';
import "./Footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReddit } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
    return (
        <div id='footer' >

            <div className='links'>
            <a href="https://www.reddit.com/user/cteters" target="_link">
                <i>
                    <FontAwesomeIcon icon={faReddit} />
                </i>
            </a>
                <span> </span>
            <a href="https://twitter.com/Christeters" target="_link">
                <i>
                    <FontAwesomeIcon icon={faTwitter} />
                </i>
            </a>
                <span> </span>
            <a href="https://github.com/cteters" target="_link">
                <i>
                    <FontAwesomeIcon icon={faGithub} />
                </i>
            </a>
                <span> </span>
            <a href="https://www.linkedin.com/in/cteters/" target="_link">
                <i>
                    <FontAwesomeIcon icon={faLinkedin} />
                </i>
            </a>
                <span> </span>
            <a href="mailto:cteters@gmail.com">
                <i>
                    <FontAwesomeIcon icon={faEnvelope} />
                </i>
                </a>

                </div>

            <p>
            Last updated in June of 2021
            </p>

        </div>
    );
};

export default Footer;