import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Projects.css";

import Git from "../../assets/img/What-is-Git.png";
import simrig from "../../assets/img/simrig.jpg";
import fanMod from "../../assets/img/fan-mod.png";
import react from "../../assets/img/React.png";
import cplusplus from "../../assets/img/C++.svg";
import WA from "../../assets/img/WebAssembly.svg";
import wa from "../../assets/img/WebAssembly.png";
import ortho from "../../assets/img/OrthoFreeD.png";

import tinkercad from "../../assets/img/tinkercad.png";
import python from "../../assets/img/python.png";
import Csharp from "../../assets/img/c#.png";
import postgresql from "../../assets/img/postgresql.png";
import rust from "../../assets/img/rust.png";
import arduino from "../../assets/img/arduino.png";
import fusion360 from "../../assets/img/fusion360.png";
import DIY from "../../assets/img/DIY.png";
import rocket from "../../assets/img/rocket.png";
import nodejs from "../../assets/img/nodejs2.png";
import javascript from "../../assets/img/javascript.png";


export default function Projects() {

    const settings = {
        className: "center",
        centerMode: true,

        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,

        autoplay: true,
        autoplaySpeed: 2500,

        dots: true,
    };

    return (
        <div className="background">
        <div className="container" id='projects'>
            <div className="headline">PERSONAL PROJECTS</div>
            <div style={{ padding: 10}}></div>
            <Slider {...settings}>










                <div className="wrapper">
                    <div className="card">
                        <div className="card__image">
                            <img src={ortho} alt="git" className="photo" />
                        </div>
                        <div className="card__type card__type">Capstone Project</div>
                        <div className="card__name">OrthoFreeD</div>
                        <div className="card__description">
                            A web application for the display and manipulation of 3D models to assist with the development of prosthetics and orthotics.
                        </div>
                        <div className="sources">
                                <div className="source"><a href="https://github.com/MaxVanRaden/Gaffney-Orthotics-Capstone-Project" target="_blank">Source Code</a></div>
                                <div className="source"><a href="https://www.youtube.com/watch?v=bgnzrqsUQQw&ab_channel=BruceIrvin" target="_blank">Presentation</a></div>
                                <div className="source"><a href="https://wa-orth.web.app/" target="_blank">Demo</a></div>
                        </div>
                        <div className="card__stats card__stats clearfix">
                            <div className="one-third no-border">
                                <div className="stat"><img src={react} alt="git" className="photo" /></div>
                                <div className="ASSEMBLY">React</div>
                            </div>

                            <div className="one-third no-border">
                                <div className="stat"><img src={cplusplus} alt="git" className="photo" /></div>
                            </div>

                            <div className="one-third no-border">
                                <div className="stat"><img src={WA} alt="git" className="photo" /></div>
                                <div className="ASSEMBLY">WASM</div>
                            </div>

                        </div>

                    </div>
                </div>






                <div className="wrapper">
                    <div className="card">
                        <div className="card__image">
                            <img src={Git} alt="git" className="photo" />
                        </div>
                        <div className="card__type card__type">Full Stack Application</div>
                        <div className="card__name">BroadBoards</div>
                        <div className="card__description">
                            A forum-based social media sandbox for learning key concepts to a full stack application.
                        </div>
                        <div className="sources">
                                <div className="source"><a href="https://github.com/bradlet/broadboards" target="_blank">Source Code</a></div>
                        </div>
                        <div className="card__stats card__stats clearfix">
                            <div className="one-third no-border">
                                <div className="stat"><img src={react} alt="git" className="photo" /></div>
                                <div className="ASSEMBLY">React</div>
                            </div>

                            <div className="one-third no-border">
                                <div className="stat"><img src={postgresql} alt="git" className="photo" /></div>
                            </div>

                            <div className="one-third no-border">
                                <div className="stat"><img src={nodejs} alt="git" className="photo" /></div>
                            </div>

                        </div>

                    </div>
                </div>





                <div className="wrapper">
                    <div className="card">
                        <div className="card__image">
                            <img src={Git} alt="git" className="photo" />
                        </div>
                        <div className="card__type card__type">Audio Tuning Sandbox</div>
                        <div className="card__name">Playdio</div>
                        <div className="card__description">
                            A digital audio sandbox for learning key concepts to digital sound.
                        </div>
                        <div className="sources">
                                <div className="source"><a href="https://github.com/cteters/playdio" target="_blank">Source Code</a></div>
                        </div>
                        <div className="card__stats card__stats clearfix">
                            <div className="one-third no-border">

                            </div>

                            <div className="one-third no-border">
                                <div className="stat"><img src={python} alt="git" className="photo" /></div>
                            </div>

                            <div className="one-third no-border">

                            </div>

                        </div>

                    </div>
                </div>







                <div className="wrapper">
                    <div className="card">
                        <div className="card__image">
                            <img src={Git} alt="git" className="photo" />
                        </div>
                        <div className="card__type card__type">Web Radio & Media Player</div>
                        <div className="card__name">Rustio</div>
                        <div className="card__description">
                            This is a project that I hope to return to soon, but for now it is only a proof of concept.
                        </div>
                        <div className="sources">
                                <div className="source"><a href="https://github.com/cteters/rustadio" target="_blank">Source Code</a></div>
                                <div className="source"><a href="https://www.youtube.com/watch?v=20fgzlz7iBA&ab_channel=ChristopherTeters" target="_blank">Presentation</a></div>
                        </div>
                        <div className="card__stats card__stats clearfix">
                            <div className="one-third no-border">
                                <div className="stat"><img src={javascript} alt="git" className="photo" /></div>
                            </div>

                            <div className="one-third no-border">
                                <div className="stat"><img src={rust} alt="git" className="photo" /></div>
                            </div>

                            <div className="one-third no-border">
                                <div className="stat"><img src={rocket} alt="git" className="photo" /></div>
                            </div>

                        </div>

                    </div>
                </div>








                <div className="wrapper">
                    <div className="card">
                        <div className="card__image">
                            <img src={Git} alt="git" className="photo" />
                        </div>
                        <div className="card__type card__type">Maze Solving Algorithm</div>
                        <div className="card__name">mazesolvin</div>
                        <div className="card__description">
                            I had a lot of fun implementing a few popular algorithms for finding the shortest path between two locations in a 2D maze.
                        </div>
                        <div className="sources">
                                <div className="source"><a href="https://github.com/cteters/mazesolving" target="_blank">Source Code</a></div>
                        </div>
                        <div className="card__stats card__stats clearfix">
                            <div className="one-third no-border">

                            </div>

                            <div className="one-third no-border">
                                    <div className="stat"><img src={python} alt="git" className="photo" /></div>
                            </div>

                            <div className="one-third no-border">

                            </div>

                        </div>

                    </div>
                </div>













                <div className="wrapper">
                    <div className="card">
                        <div className="card__image">
                            <img src={simrig} alt="git" className="photo" />
                        </div>
                        <div className="card__type card__type">Tinkering</div>
                        <div className="card__name">Sim Racing</div>
                        <div className="card__description">
                            My most current hobby focuses on an interest toward the construction and modification of a racing simulator that I have at home.
                        </div>
                        <div className="sources">
                                <div className="source"><a href="https://imgur.com/a/q3aQPs8" target="_blank">Album</a></div>
                        </div>
                        <div className="card__stats card__stats clearfix">
                            <div className="one-third no-border">
                                <div className="stat"><img src={DIY} alt="git" className="photo" /></div>
                            </div>

                            <div className="one-third no-border">
                                <div className="stat"><img src={arduino} alt="git" className="photo" /></div>
                            </div>

                            <div className="one-third no-border">
                                <div className="stat"><img src={tinkercad} alt="git" className="photo" /></div>
                            </div>

                        </div>

                    </div>
                </div>





                <div className="wrapper">
                    <div className="card">
                        <div className="card__image">
                            <img src={fanMod} alt="git" className="photo" />
                        </div>
                        <div className="card__type card__type">Tinkering</div>
                        <div className="card__name">3D Printing & CAD</div>
                        <div className="card__description">
                            I've recently discovered an interest toward the design and modification of objects in studios such as Tinkercad
                        </div>
                        <div className="sources">
                                <div className="source"><a href="https://www.thingiverse.com/cteters/designs" target="_blank">Published Work</a></div>
                        </div>
                        <div className="card__stats card__stats clearfix">
                            <div className="one-third no-border">

                            </div>

                            <div className="one-third no-border">
                                <div className="stat"><img src={tinkercad} alt="git" className="photo" /></div>
                            </div>

                            <div className="one-third no-border">

                            </div>

                        </div>

                    </div>
                </div>











            </Slider>
            </div>
            </div>
    );
}