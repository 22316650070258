import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faWrench, faExclamation} from "@fortawesome/free-solid-svg-icons";
import "./Services.css";

const Services = () => {
	return (
		<>
			<div className="container" id= 'services'>
				<div className="headline">Services</div>
				<div className='services'>



			<div className="service">
				<div className='font_awesome' >
					<FontAwesomeIcon icon={faCode} />
				</div>
				<h2>Experienced</h2>
				<p> Proficient programmer; having knowledge and appreciation for many underlining fundamentals, such as understanding what makes for good algorithmic efficiency, applying appropriate data structures and following a basic clean code philosophy.</p>
			</div>




			<div className="service">
				<div className='font_awesome' >
					<FontAwesomeIcon icon={faWrench} />
				</div>
				<h2>Technical</h2>
				<p> Wide range of experience in the art of troubleshooting in many disciplines. Certified aircraft electrician with 7 years of experience, operated as a radio operator for a year in the military and volunteered for a year at PSU as an information technology specialist.</p>
			</div>



			<div className="service">
				<div className='font_awesome' >
					<FontAwesomeIcon icon={faExclamation} />
				</div>
				<h2>Inventive</h2>
				<p> Do-it-yourself hobbyist that loves to take on the challenge of a new project, such as programming microcomputers to perform small tasks around the house or brewing a large batch of beer in the backyard on a good day.</p>
			</div>






				</div>
				</div>
			</>
	);
};

export default Services;
