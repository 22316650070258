import React from "react";
import './About.css';
import Pdf from "../../assets//doc/ChrisTeters.pdf";


require('typeface-roboto-slab');


function About() {


	return (
		<>

			<div className="container" id='about'>
				<div className="headline">
					ABOUT ME
				</div>
				<p>
					I am a recent Computer Science graduate from Portland State University
				</p>
				<a href={Pdf} target="_blank" className="resume">Personal Resume</a>
			</div>
		</>
	);
}

export default About;
