import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testimonials.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

export default function Testimonials() {

    const settings = {


        className: "center",
        draggable: true,
        centerMode: true,
        infinite: true,
        centerPadding: "100px",

        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,

        arrows: false,
    };

    return (
        <div className="container" id='testimonials'>
            <div className="headline">
                POSITIVE FEEDBACK
                <br />
                ABOUT ME
            </div>
            <div style={{ padding: 10 }}></div>
            <Slider {...settings}>



                <div>
                    <yellow>
                        Christopher was able to competently tutor multiple CS classes and quickly became a sought- after resource.
                        His strengths of clear communication, attention to detail, and coding knowhow were essential to ensuring that the Academic Computer Lab provided quality tutoring.
                        <div>
                            <div className="quote">
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <address>
                                <a href="">Josh Aman </a>
                            </address>
                        </div>
                        <div className="position">
                            Coordinator, Tutoring and Academic Computer Lab
                        </div>
                     </yellow>
                </div>




                <div>
                    <green>
                        Demonstrated exceptional technical proficiency within all facets of the Cavalry Scout realm;
                        exhibited the uncanny ability to instruct as per field manual in an effective and informative matter.
                        <div>
                            <div className="quote">
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <address>
                                <a href="">1LT Roe, Kyle</a>
                            </address>
                        </div>
                        <div className="position">
                            Platoon Leader, C TRP 1-82 CAV
                        </div>
                     </green>
                </div>



                <div>
                    <blue>
                        Badged and tracked over 600 personnel, enabling the occupants of the camp to perform their mission.
                        Sergeant Teters was recognized by Task Force members for his excellent customer service in the execution of his duties.
                        While working with the contractors and local nationals, he fostered a meaningful, long lasting, and trusting relationship which improved the security of the camp.
                        <div>
                            <div className="quote">
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <address>
                                <a href="">1LT Neville, Justin</a>
                            </address>
                        </div>
                        <div className="position">
                            Executive Officer, D Co 1/186 INF
                        </div>
                     </blue>
                </div>








                <div>
                    <orange>
                        I rate SSG Teters within the top 75% of C TRP Staff Sergeants.
                        SSG Teters possesses great potential and would excel in administratively driven roles.
                        Functioned as Platoon Sergeant for Scout and Headquarters Platoon, accounting for 20 soldiers, 4 Strykers and $2 Million in equipment.
                        <div>
                            <div className="quote">
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <address>
                                <a href="">1LT Osborn, Joshua</a>
                            </address>
                        </div>
                        <div className="position">
                            Executive Officer, C TRP 1-82 CAV
                        </div>
                    </orange>
                </div>








                <div>
                    <pink>
                        Demonstrated a high level of competency in technical knowledge of the unit's communication equipment.
                        Maintained 100% accountability for all crew and self assigned equipment; an excess of $7,000,000.
                        <div>
                            <div className="quote">
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <address>
                                <a href="">1LT John Evans</a>
                            </address>
                        </div>
                        <div className="position">
                            Platoon Leader, C TRP 1-82 CAV
                        </div>
                     </pink>
                </div>








            </Slider>
        </div>
    );
}