import React, { useEffect, useState } from "react";
import "./Navbar.css";

import profile from "../../assets/img/clear-pink.png";


export default function Navbar() {

    const [classesNav, setClassesNav] = useState("");

    useEffect(() => {
        const handleScroll = () => {
            let navbar = document.getElementById("navbar");
            let sticky = navbar.offsetTop;

            if (window.pageYOffset >= sticky) {
                setClassesNav("sticky");
            } else {
                setClassesNav("");
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    });


    return (
        <>
            <div id="navbar" className={classesNav}>
                <a href="#home" className="active">
                    <img src={profile} alt="git" className="pic" />
                    Chris Teters
                </a>
                <a href="#about">ABOUT</a>
                <a className="anchor" href="#projects">PROJECTS</a>
                <a href="#services">SERVICES</a>
                <a href="#testimonials">TESTIMONIALS</a>
            </div>
        </>
    );
};